@import 'tailwindcss/base';

.tutorial {
  @apply space-y-2;
}

 h1 {
  @apply text-2xl font-semibold;
}

 h2 {
  @apply text-3xl font-extrabold;
}

 h3 {
  @apply text-lg font-extrabold;
}

 h4 {
  @apply text-base font-extrabold;
}

 h5 {
  @apply text-sm font-extrabold;
}

 h6 {
  @apply text-xs font-extrabold;
}

 p {
  @apply text-base mb-3 text-gray-500 dark:text-gray-400 py-2;
}

.submit-btn {
  @apply w-full p-2 mt-4 text-white font-semibold rounded;
  @apply bg-gradient-to-r from-sky-400 to-emerald-600;
  @apply hover:from-sky-500 hover:to-emerald-700;
}

.submit-btn-warm {
  @apply w-full p-2 mt-4 text-white font-semibold rounded;
  @apply bg-gradient-to-r from-orange-400 to-orange-500;
  @apply hover:from-orange-500 hover:to-orange-600;
  }

.submit-btn-loading {
  @apply w-full p-2 mt-4 text-white font-semibold rounded bg-gray-500 cursor-not-allowed;
}

.error-message {
  color: #b30202;
}

@keyframes dot {
  0%, 80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.submit-btn-loading span {
  display: inline-block;
}

.submit-btn-loading span.dot1,
.submit-btn-loading span.dot2,
.submit-btn-loading span.dot3 {
  display: inline-block;
  margin-left: 4px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #fff;
  animation: dot 2s infinite;
}

.submit-btn-loading span.dot1 {
  animation-delay: 0s;
}

.submit-btn-loading span.dot2 {
  animation-delay: 0.5s;
}

.submit-btn-loading span.dot3 {
  animation-delay: 1s;
}

.banner-container {
  min-height: 0;
  display: flex;
  flex-direction: column;
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  z-index: 9999;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9998;
}

.nav-bar {
  display: flex;
  list-style-type: none;
  justify-content: space-evenly;
  font-size: 14px;
  margin-bottom: 1rem;
}

.nav-bar li {
  margin: 0 0.5rem;
}

.nav-bar a {
  color: #444;
  text-decoration: none;
}

.nav-bar a:hover {
  color: #777;
  text-decoration: underline;
}
